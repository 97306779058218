import type { PortableTextBlock } from '@portabletext/types'
import React from 'react'

import Blocks from '@/components/Blocks'

interface DefaultHeroProps {
  title: string
  excerpt?: PortableTextBlock[]
}

const DefaultHero: React.FC<DefaultHeroProps> = ({ title, excerpt }) => {
  return (
    <section className="pt-20 pb-0">
      <div className="container mx-auto px-4">
        <div className="space-y-4 max-w-full sm:w-3/4 md:w-4/5 lg:w-3/5">
          <h1 className="text-5xl">{title}</h1>
          {excerpt && <Blocks value={excerpt} />}
        </div>
      </div>
    </section>
  )
}

export default DefaultHero
